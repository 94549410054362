body {
    background-color: transparent;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/* 
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
    font-family: 'Quantico';
    font-weight: 400;
    src: url(/src/fonts/Quantico-Regular.ttf);
}

@font-face {
    font-family: 'Quantico';
    font-weight: 700;
    src: url(/src/fonts/Quantico-Bold.ttf);
}

@font-face {
    font-family: 'Open Sans';
    src: url(/src/fonts/OpenSans-VariableFont_wdth\,wght.ttf);
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(/src/fonts/OpenSans-Italic-VariableFont_wdth\,wght.ttf);
    font-style: italic;
}
